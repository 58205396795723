<template>
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h6>Danh sách mạng</h6>			
				</a-col>
			</a-row>
		</template>
		<a-table v-if="!fetchListing" :columns="columns" :data-source="rows" :pagination="false">
			<template slot="created" slot-scope="created">
				<span>{{ created | moment("DD-MM-YYYY") }}</span>
			</template>
		</a-table>
		<a-spin v-else tip="Loading..."></a-spin>
		<div class="table-upload-btn">
			<createPoup @reload="getData" />
		</div>
	</a-card>
</template>

<script>

	import listing from "@/services/network/listing"
	import createPoup from "./create"

	export default ({
		components: {
			createPoup
		},
		data() {
			return {
				fetchListing: true,
				rows: [],
				columns: [
					{
						title: 'Tên',
						dataIndex: 'name'
					},
					{
						title: 'ID',
						dataIndex: 'id'
					},
					{
						title: 'created',
						dataIndex: 'created',
						scopedSlots: { customRender: 'created' },
					}
				]
			}
		},
		created() {
			this.getData()
		},
		methods: {
			getData() {
				listing()
				.then(res=> {
					this.fetchListing = false
					if (this._.isObject(res)) {
						this.rows = res
					}
				})
			}
		}
	})

</script>